.App {
  text-align: center;
}

@font-face {
    font-family: "Stint Ultra Condensed";
    src: url(https://fonts.gstatic.com/s/stintultracondensed/v8/NoM1Og8UFiGn7Uk2pCv5X5ZcKg.woff2) format("woff2");
    font-weight: 400;
    font-style: normal;
}


