.row-direction{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.custom-link{
    color: #ffff;
    text-decoration: none;
    word-wrap: break-word;
    display:flex;
    justify-content: center;
}

.custom-link:hover{
    color: #BED754;
}

.skills-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.avatar {
    display: flex;
    justify-content: center;
}


